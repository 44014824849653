// @flow
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';

const whitelisted = [process.env.GATSBY_SITE_URL];

const locales = ['en-AU', 'en-US', 'es'];

const urlMatches = (urlA: string, urlB: string): boolean => {
  if (urlA === urlB) {
    return true;
  }
  if (urlB.endsWith('/')) {
    if (urlB.slice(0, urlB.length - 1) === urlA) {
      return true;
    }
  }
  return false;
};

const isValidUrl = (url: string): boolean => {
  const matchedUrl = whitelisted.find((whitelistedUrl: string) => {
    if (urlMatches(whitelistedUrl, url)) {
      return true;
    }
    for (let i = 0, len = locales.length; i < len; i++) {
      const locale = locales[i];
      const localeUrl = `${whitelistedUrl}/${locale}`;
      if (urlMatches(localeUrl, url)) {
        return true;
      }
    }
    return false;
  });
  return !!matchedUrl;
};

const redirectTo = (state: string, params: string) => {
  state = state.replace(/^\//, '');

  let redirectUrl = `${window.location.origin}/${state}`;
  if (params) {
    if (state.includes('?') && !state.endsWith('?')) {
      redirectUrl += '&';
    } else if (!state.includes('?')) {
      redirectUrl += '?';
    }
    redirectUrl += params;
  }

  const isValid = isValidUrl(redirectUrl.split('?')[0]);

  if (!isValid) {
    navigate(`/error?error=invalid_redirect`);
  }

  try {
    window.location.replace(redirectUrl);
  } catch (e) {
    window.location.href = redirectUrl;
  }
};

const AuthPage = () => {
  const [error, setError] = useState(false);

  const handleError = () => {
    console.error(`Missing redirect params.`);
    setError(true);
  };

  useEffect(() => {
    const parsedHash = queryString.parse(window.location.hash);
    const { state, access_token: accessToken } = parsedHash;
    if (!accessToken) {
      const parsedParams = queryString.parse(window.location.search);
      if (parsedParams.error) {
        if (parsedParams.state) {
          redirectTo(parsedParams.state, `error=${parsedParams.error}`);
        } else {
          handleError();
          return;
        }
      } else {
        handleError();
        return;
      }
    }
    if (!state) {
      handleError();
      return;
    }
    redirectTo(state, `accessToken=${accessToken}`);
  }, []);
  return <div>{error ? 'Something went wrong.' : 'You will automatically be redirected...'}</div>;
};

export default AuthPage;
